import React from 'react';
import { Link } from 'gatsby';

const BlogPager = ({ previousPagePath, nextPagePath, numberOfPages, humanPageNumber }) => {
  return (
    <div className="px-gutter flex justify-between py-10 text-lg">
      {previousPagePath && <Link to={previousPagePath}>&lsaquo; Previous</Link>}
      {!previousPagePath && <span />}
      {numberOfPages > 1 && (
        <div>
          {numberOfPages} of {humanPageNumber}
        </div>
      )}
      {!nextPagePath && <span />}
      {nextPagePath && <Link to={nextPagePath}>Next &rsaquo;</Link>}
    </div>
  );
};

export default BlogPager;
