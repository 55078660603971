import React from 'react';
import { Link } from 'gatsby';

import { SanityImage } from '@components/sanity/sanityImage';

const FeaturedCard = ({ title, postDate, excerpt, postImage, slug }) => {
  return (
    <Link to={`/news/${slug?.current}`} className="grid grid-cols-1 gap-x-10 lg:grid-cols-5">
      <div className="aspect-w-5 aspect-h-4 relative  rounded-2xl overflow-hidden lg:col-start-3 lg:col-end-6">
        <SanityImage
          image={postImage}
          className="absolute top-0 left-0 object-cover w-full h-full"
        />
      </div>
      <div className="text-left grid gap-y-2 mt-4 lg:col-start-1 lg:row-start-1 lg:self-center lg:col-end-3 lg:gap-y-6">
        <h4 className="subThree">{postDate}</h4>
        <h2 className="blockH2 font-medium">{title}</h2>
        <p className="text-lg">{excerpt}</p>
        <div>
          <div className="btn">Read more</div>
        </div>
      </div>
    </Link>
  );
};

export default FeaturedCard;
