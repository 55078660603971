import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';
import BlogCard from '@components/blog/blogCard';
import FeaturedCard from '@components/blog/featuredCard';
import BlogPager from '@components/blog/blogPager';

const NewsPortal = ({ data: { page, posts }, pageContext }) => {

  //sort posts.nodes by postDate
  const sortedPosts = posts.nodes.sort((a, b) => {
    return new Date(b.postDate) - new Date(a.postDate);
  }
  );

  return (
    <Layout overlayHeader hideSub>
      <PageMeta {...page?.seo} />
      <div className="px-gutter bg-sand-900 py-[200px] text-center">
        <h1 className="blockH0 mb-one">News</h1>
        <PortableTextBlock text={page?.introText} className="max-w-3xl mx-auto" />
        <div className="pt-32">
          <FeaturedCard {...sortedPosts[0]} />
        </div>
      </div>
      {/*  */}
      <div className="px-gutter grid grid-cols-1 gap-10 py-space-m md:grid-cols-3">
        {sortedPosts.slice(1).map((post) => (
          <BlogCard {...post} />
        ))}
      </div>
      {/*  */}
      <BlogPager {...pageContext} />
    </Layout>
  );
};

export default NewsPortal;

export const pageQuery = graphql`
  query NewsPortalQuery($skip: Int!, $limit: Int!) {
    page: sanityBlogPortal {
      introText: _rawIntroText(resolveReferences: { maxDepth: 10 })
      seo {
        ...SEO
      }
    }
    posts: allSanityBlogPost(skip: $skip, limit: $limit) {
      nodes {
        postDate: _createdAt(formatString: "MMMM DD, YYYY")
        title
        excerpt
        postImage {
          ...ImageWithPreview
        }
        slug {
          current
        }
      }
    }
  }
`;
